<template>
	<div class="login-view">
		<div class="login-content">
			<el-form class="login-form" :model="loginForm" status-icon :rules="rules" ref="loginForm" label-width="60px"
				@keyup.enter.native="submitForm('loginForm')">
				<div class="login-brand">登录系统</div>
				<el-form-item label="终端" prop="terminal" v-show="false">
					<el-input type="terminal" v-model="loginForm.terminal" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="用户名" prop="username">
					<el-input type="username" v-model="loginForm.username" autocomplete="off"
						placeholder="用户名"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('loginForm')" v-show="!waitAuth">登录</el-button>
          <el-button type="primary" v-show="waitAuth">等待授权{{authTime}}秒</el-button>
<!--					<el-button @click="resetForm('loginForm')">清空</el-button>-->
				</el-form-item>
			</el-form>
		</div>
		<icp></icp>
	</div>

</template>

<script>
	import Icp from '../components/common/Icp.vue'
	export default {
		name: "login",
		components: {
			Icp
		},
		data() {
			var checkUsername = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入用户名'));
				}
				callback();
			};
			var checkPassword = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				}
				callback();

			};
			return {
        waitAuth: false,
        authTime: 60,
				loginForm: {
					terminal: this.$enums.TERMINAL_TYPE.WEB,
					username: '',
					password: ''
				},
				rules: {
          username: [{
						validator: checkUsername,
						trigger: 'blur'
					}],
					password: [{
						validator: checkPassword,
						trigger: 'blur'
					}]
				},
        webSocket: null,
			};
		},
		methods: {
			submitForm(formName) {
        let that = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
            that.waitAuth = true
            let waitFunc = setInterval(function (){
              that.authTime = that.authTime - 1
              if(that.authTime <= 0) {
                clearInterval(waitFunc)
                that.waitAuth = false
                that.authTime = 60
              }
            }, 1000)
						this.$http({
								url: "/loginTG",
								method: 'post',
								data: {
                  username : that.loginForm.username,
                  token: that.$route.query.token,
                  terminal: that.loginForm.terminal,
                }
							})
							.then((data) => {
                let deviceNo = data.result;
                console.log(deviceNo)
                // ws初始化
                this.$wsApi.connect(process.env.VUE_APP_TG_WS_URL + deviceNo, "");
                this.$wsApi.onMessage((cmd, msgInfo) => {
                  console.log('消息来了', cmd);
                  if (cmd == 200) {
                    console.log(msgInfo)
                    sessionStorage.setItem("botId", msgInfo.botId);
                    this.$http({
                      url: "/autoRegisterFromTG",
                      method: 'post',
                      data: {
                        userName : deviceNo,
                        password: '123456',
                        nickName: that.loginForm.username,
                        botId: msgInfo.botId,
                        chatUserList: msgInfo.chatList,
                      }
                    })
                    .then((ret) => {
                      sessionStorage.setItem("accessToken", ret.accessToken);
                      sessionStorage.setItem("refreshToken", ret.refreshToken);
                      sessionStorage.setItem("tgToken", that.$route.query.token);
                      sessionStorage.setItem("deviceNo", deviceNo);
                      that.$message.success("登录成功");
                      that.$router.push("/home/chat");
                      setTimeout(function (){
                        location.reload(true);
                      }, 1000);
                    })
                  }
                })
							})
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 获取cookie、
			getCookie(name) {
				let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
				let arr = document.cookie.match(reg)
				if (arr) {
					return unescape(arr[2]);
				}
				return '';
			},
			// 设置cookie,增加到vue实例方便全局调用
			setCookie(name, value, expiredays) {
				var exdate = new Date();
				exdate.setDate(exdate.getDate() + expiredays);
				document.cookie = name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate
					.toGMTString());
			},
			// 删除cookie
			delCookie(name) {
				var exp = new Date();
				exp.setTime(exp.getTime() - 1);
				var cval = this.getCookie(name);
				if (cval != null) {
					document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
				}
			}
		},
		mounted() {
			this.loginForm.userName = this.getCookie("username");
			// cookie存密码并不安全，暂时是为了方便
			this.loginForm.password = this.getCookie("password");
		}
	}
</script>

<style scoped lang="scss">
	.login-view {
		width: 100%;
		height: 100%;
		background: rgb(232, 242, 255);
		background-size: cover;
		box-sizing: border-box;


		.login-content {
			position: relative;
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding: 10%;

			.login-intro {
				flex: 1;
				padding: 40px;
				max-width: 600px;

				.login-title {
					text-align: center;
					font-weight: 600;
					font-size: 30px;
				}

				.login-icons {
					display: flex;
					align-items: center;

					.login-icon {
						padding-left: 5px;
					}
				}
			}

			.login-form {
				height: 340px;
				width: 400px;
				padding: 30px;
				background: white;
				opacity: 0.9;
				box-shadow: 0px 0px 1px #ccc;
				border-radius: 3%;
				overflow: hidden;
				border: 1px solid #ccc;

				.login-brand {
					line-height: 50px;
					margin: 30px 0 40px 0;
					font-size: 22px;
					font-weight: 600;
					letter-spacing: 2px;
					text-transform: uppercase;
					text-align: center;
				}

				.register {
					display: flex;
					flex-direction: row-reverse;
					line-height: 40px;
					text-align: left;
					padding-left: 20px;
				}
			}
		}
	}
</style>
